.description {
  padding: 10px 25px;
  width: 100%;
  color: #333;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
}

.description_content {
  text-align: center;
  margin-bottom: 2rem;
}

.read-more-less--more,
.read-more-less--less {
  color: rgb(61, 157, 241);
}

@media (max-width: 900px) {
  .description .description_content {
    width: 80vw;
    text-align: center;
    padding: 12px;
  }
}
