.search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.search .church_lable {
  background-color: #fff;
  padding: 14px 14px 13px 14px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: 0 0 50px 0;
  cursor: pointer;
  color: #999;
}

.search .church_input {
  padding: 16px 7.5px;
  margin-left: 10px;
  border: none;
  width: 300px;
  border-left: 1px solid rgb(230, 228, 228);
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  outline-width: 0.1px;
  margin-top: 8px;
}

.search .search_button {
  color: #33ddf5;
  background-color: #fff;
  padding: 18px 20px 18px 20px;
  border-radius: 10px;
  margin: 0 0 12px 10px;
  cursor: pointer;
  border: none;
  outline: none;
}
.search .search_button:hover {
  color: #33ddf5;
  background-color: #f4f4f4;
  transition: 0.7s;
  /* font-size: 16px; */
}

.form_control {
  min-width: 180px;
}

@media (max-width: 900px) {
  .search .church_input {
    width: 70vw;
  }
  input,
  input::-webkit-input-placeholder {
    font-size: 12px;
  }
  .search .church_lable {
    width: 20%;
  }
  .search .search_button {
    margin: 0.2rem;
  }
}
