/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  tr {
    border: 1px solid #ccc !important;
  }

  td {
    /* Behave  like a "row" */
    border: none !important;
    border-bottom: 1px solid #eee !important;
    position: relative !important;
    padding-left: 50% !important;
  }

  td:before {
    /* Now like a table header */
    position: absolute !important;
    /* Top/left values mimic padding */
    top: 6px !important;
    left: 6px !important;
    width: 45% !important;
    padding-right: 10px !important;
    white-space: nowrap !important;
  }

  /*
	Label the data
	*/
  td:nth-of-type(1):before {
    content: "Profile Picture";
    font-weight: 600;
  }
  td:nth-of-type(2):before {
    content: "Church Id";
    font-weight: 600;
  }
  td:nth-of-type(3):before {
    content: "Established At";
    font-weight: 600;
  }
  td:nth-of-type(4):before {
    content: "Church Name";
    font-weight: 600;
  }
  td:nth-of-type(5):before {
    content: "Church Title";
    font-weight: 600;
  }
  td:nth-of-type(6):before {
    content: "Admin Email";
    font-weight: 600;
  }
  td:nth-of-type(7):before {
    content: "Status";
    font-weight: 600;
  }
}
