.church_details {
  display: flex;
  flex-direction: column;
}

.details {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.detail_header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.detail_header h5 {
  margin: 0 0 0 60px;
  background-color: rgb(179, 25, 25);
  color: #fff;
  padding: 15px;
  width: 130px;
  cursor: pointer;
  transition: 0.4s;
}

.detail_header h5:hover {
  background-color: rgb(209, 71, 71);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
}

@media (max-width: 900px) {
  .church_details .detail_image {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
  }
  .detail_header {
    flex-direction: column;
  }
  .detail_header h5 {
    margin: 15px 0;
  }
}
