.ReactTags__tagInputField {
  width: 100%;
  height: 3.4rem;
  padding-left: 10px;
  border-radius: 3px;
  border: 1px solid #b3b1b1;
  outline-color: #043c70;
  margin-top: 10px;
}

.tag-wrapper {
  padding: 6px;
  margin: 15px 7px 7px 7px;
  background-color: #f4f4f4;
}

.ReactTags__remove {
  margin-left: 3px;
}

.ReactTags__selected {
  margin-top: 10px;
}

.inputComponent {
  border: 1px solid #b3b1b1;
  text-align: left;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.react-tagsinput-input {
  width: 200px !important;
}

.MuiTextField-root {
  min-width: 100% !important;
}

.additional_tabot {
  text-align: left !important;
}

.react-tagsinput-tag {
  background-color: #e1e9d2 !important;
}

input[type="button"] {
  padding: 10px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

.MuiGrid-root {
  margin-top: 0;
}

@media (max-width: 320px) {
  .MuiOutlinedInput-adornedEnd {
    width: inherit;
    margin-bottom: 15px;
  }
}
