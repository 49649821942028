@import url("https://fonts.googleapis.com/css2?family=Alegreya&display=swap");

* {
  margin: 0;
  padding: 0;
}

.nav_bar {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #33ddf5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  /* position: fixed;
  z-index: 9; */
  /* padding-bottom: 40px; */
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.menu ul {
  list-style: none;
}

.menu ul li {
  margin: 0 15px 0 5px;
}

.left_menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
  color: #fff;
}

.right_menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 30px;
}

.post_church {
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: 0.7s;
}

.post_church:hover {
  color: #333;
}

.right_menu .account {
  color: #fff;
  cursor: pointer;
  margin: 0;
}
.right_menu .messaging,
.notification {
  color: #555;
  font-size: x-small;
  cursor: pointer;
}

.link a {
  text-decoration: none;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 26px;
}

@media (max-width: 340px) {
  .link a {
    font-size: 14px;
    width: 100%;
  }

  .left_menu {
    width: 100%;
  }
}
