.header_address {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 4px;
  color: #333;
  margin: 20px 10px;
  padding: 5px;
}

.header_address p {
  font-size: small;
}

.header_content,
.description_content {
  text-align: start;
}

.direction {
  color: #00c1da;
}

.read-more-button {
  color: rgb(101, 101, 236);
}

@media (max-width: 900px) {
  .header_address {
    width: 90vw;
  }
  .header_content {
    width: 90vw;
  }
}
