/* .church_info_side {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  list-style: none;
}

.church_info_side ul {
  list-style: none;
}



.church_info_side ul .list_detail li {
  font-size: smaller;
  margin-left: 1rem;
}

.church_info_side .church_show_only {
  border-bottom: 1px solid rgb(236, 224, 224);
  padding: 20px 20px 20px 0;
  text-align: start;
} */
.church_info_side ul .social_list_detail {
  display: flex;
  gap: 10px;
  margin-top: 0.5rem;
  justify-content: center;
}