.church_info_side {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* background-color: #f4f4f4; */
  width: 80%;
  margin: 40px 0 0 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.church_info_side h2 {
  color: rgb(83, 80, 80);
  line-height: 1.7em;
  font-size: 2rem;
}

.church_info_side ul {
  list-style: none;
}

.church_info_side .check_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0 15px 0;
}

input[type="checkbox"] {
  transform: scale(1.5);
  background-color: #333;
}

.church_info_side ul .check_box p {
  margin-left: 10px;
}

.church_info_side .church_show_only {
  border-bottom: 1px solid rgb(236, 224, 224);
  padding: 20px 20px 20px 0;
}

@media (max-width: 426px) {
  .church_info_side h2 {
    margin: 1rem 0.5rem -2rem 0.5rem;
    color: rgb(83, 80, 80);
    line-height: 1.7em;
    font-size: 1rem;
  }
}
