.footer {
  background-color: #f4f4f4;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer_top {
  display: flex;
  justify-content: space-around;
}

.logo h2 {
  color: #333;
}

.social {
  font-size: 1rem;
  display: flex;
  width: 100px;
  justify-content: space-between;
}

.social a {
  text-decoration-color: #33ddf6;
}

.social a > i {
  color: #33ddf6;
  padding: 0.3em;
  transition: ease-in-out 0.7s;
}

.social a > i:hover {
  color: #07525e;
}

i {
  font-size: 1.2rem;
  color: #33ddf6;
}

.copy_right {
  font-size: 0.8rem;
}
