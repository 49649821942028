/* @import url("https://fonts.googleapis.com/css2?family=Alegreya&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
.header_info {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  /* background-color: #f5f5f5; */
  padding: 10px 25px;
  border-radius: 4px;
  border: 0.5px solid #d8d8d8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  color: #333;
  margin-top: 15px;
  transition: 0.3s;
}

.header_info:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.24);
}

.header:hover {
  background-color: #fff;
}

.header .cabab_icon i {
  cursor: pointer;
  color: #333;
}

.header_content {
  display: flex;
  align-items: center;
}

.header_content .church_image {
  margin-right: 7px;
  border-radius: 4px;
}

.header_content img {
  border-radius: 4px;
}

.church_address {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-left: 0.7rem;
}

.church_name {
  text-decoration: none !important;
  color: #717171;
  font-size: 18px;
  font-weight: 450;
  transition: 0.3s ease-in-out;
}

.church_name:hover {
  color: rgb(22, 22, 22);
}

.additional_tabot {
  color: #717171;
  font-size: 14px;
  font-weight: 300;
}

.direction_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
}

.direction {
  color: #00c1da;
}

.direction_item {
  text-decoration: none;
  color: #5b5feb;
  transition: 0.3s;
  font-size: 12px;
  margin: 0 0 9px 5px;
}

.direction_item:hover {
  color: rgb(31, 7, 251);
}

.next_page .angle_right {
  color: rgb(216, 216, 216);
}

@media (max-width: 426px) {
  .header_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* background-color: #f5f5f5; */
    /* padding: 10px 25px; */
    color: #333;
    box-shadow: none;
    margin-top: 0;
    border-radius: none;
    transition: 0.3s;
  }

  .direction_item {
    font-size: 0.7rem;
    margin: 0;
  }

  .church_address {
    margin-left: 0.2rem;
  }

  .church_name {
    font-size: 16px;
  }

  .direction_container {
    margin: 0;
  }

  /* .left_menu .link {
    font-size: 26px;
  } */

  .fa-angle-right {
    font-size: 20px;
  }

  .direction {
    font-size: 1.2rem;
    width: 24px;
    height: 24px;
  }

  .search_header {
    font-size: 0.9rem;
    margin-top: 1rem;
  }
}

@media (max-width: 1024px) {
  .info_container {
    display: flex;
    justify-content: center;
  }
  .church_name {
    font-size: 16px;
  }
  .additional_tabot {
    font-size: 14px;
  }
  .church_address h3 {
    font-size: 18px;
  }
  .MuiGrid-root {
    margin-top: 100px;
  }
}
