.church_body {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 40px;
}

.church_info_left_side {
  flex: 1;
}

.church_info {
  flex: 2;
  text-decoration: none;
}

.link {
  text-decoration: none;
  color: #fff;
  transition: 0.7s;
}

.link:hover {
  color: #333;
}
.each_church {
  display: flex;
}

@media (max-width: 900px) {
  .map {
    width: 100%;
  }
}
