/* @import url("https://fonts.googleapis.com/css2?family=Alegreya&display=swap");
body {
  font-family: "Alegreya", serif;
} */

.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.MuiInputBase-root {
  outline: none !important;
}

#establishedAt {
  margin-left: 10px;
}

/* leaflet map sizing */
.leaflet-container {
  width: 90%;
  height: 50vh;
}
.MuiFormGroup-root {
  float: left !important;
}

.makeStyles-required-37 {
  color: red;
  float: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .map {
    width: 100%;
  }
  .leaflet-container {
    width: 100%;
    margin: 0;
    height: 50vh;
  }
}
